import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../components/section-title'
import { usePostsQuery } from '../generated/graphql'
import dayjs from 'dayjs'
import striptags from 'striptags'

const Outer = styled.div`
  margin-top: 4rem;
  scroll-margin-top: 2rem;
`

const Inner = styled.div`
  margin-top: 0.25rem;
  border: 0.125rem solid #f00;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #FF7BAB, #FF921E);
  display: grid;
  --column-count: 2;
  grid-template-columns: repeat(var(--column-count), 1fr);
  @media (max-width: 40rem){
    --column-count: 1;
  }
`

const Item = styled.div`
  position: relative;
  padding: 1rem;
  &::before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: -0.0625rem;
    background-image: linear-gradient(to bottom, #FF7BAB, #FF921E);
    @media (max-width: 40rem){
      width: 100%;
      height: 0.125rem;
      top: 0;
      left: 0;
    }
  }
  &:nth-child(4n + 1):before {
    display: none;
  }
`

const Text = styled.div`
  line-height: 1.75;
  font-size: 0.875rem;
`

const PublishDate = styled.div`
  margin-top: 2rem;
  font-size: 0.75rem;
  text-align: right;
  font-weight: 700;
`

type NewsSectionProps = {

}
export const NewsSection: FunctionComponent<NewsSectionProps> = () => {
  const postsQuery = usePostsQuery()
  const posts = postsQuery.data?.posts?.nodes ?? []
  return <Outer id="news">
    <SectionTitle>
      NEWS
    </SectionTitle>
    <Inner>
      {
        posts.map((post, i) => {
          return <Item key={i}>
            <Text>
              {striptags(post?.content ?? '')}
            </Text>
            <PublishDate>
              {dayjs(post?.date).format('YYYY-MM-DD')}
            </PublishDate>
          </Item>
        })
      }
    </Inner>
  </Outer>
}
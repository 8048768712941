import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(150deg, rgba(255,234,236,1) 0%, #fee9d0 100%);
  z-index: -100;
`

type GlobalBackgroundProps = {

}
export const GlobalBackground: FunctionComponent<GlobalBackgroundProps> = () => {
  return <Outer>

  </Outer>
}
import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useExternalCss } from '../hooks/use-external-css'

const Css = createGlobalStyle`
  :root {
    font-family: 'Noto Sans JP', sans-serif;
    color: #612c26;
    scroll-behavior: smooth;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
`

export const RootCss: FunctionComponent = () => {
  useExternalCss('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;900&family=EB+Garamond:wght@600&display=swap')
  return <Css />
}
import React from 'react';
import { FunctionComponent } from 'react';
import { ResetCss } from './components/reset-css';
import { RootCss } from './components/root-css';
import { TopPage } from './pages/top-page'
import { GlobalBackground } from './components/global-background'
import { Footer } from './components/footer'
import { FloatHeader } from './components/float-header'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://rinoharuoka.com/wp/index.php?graphql',
    fetchOptions: {
      mode: 'cors'
    }
  }),
  cache: new InMemoryCache()
})

export const App: FunctionComponent = () => {
  return <>
    <ApolloProvider client={apolloClient}>
      <ResetCss />
      <RootCss />
      <GlobalBackground />
      <FloatHeader />
      <TopPage />
      <Footer />
    </ApolloProvider>
  </>
}
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../components/section-title'
import dayjs from 'dayjs'
import { GradientText } from '../components/gradient-text'
import { usePublicationsQuery } from '../generated/graphql'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const Outer = styled.div`
  margin-top: 4rem;
  scroll-margin-top: 2rem;
`

const Inner = styled.div`
  margin-top: 0.25rem;
  border: 0.125rem solid #f00;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #FF7BAB, #FF921E);
  display: grid;
  --column-count: 2;
  grid-template-columns: repeat(var(--column-count), 0.6fr 1fr);
  grid-auto-rows: min-content min-content 1fr;
  @media (max-width: 50rem){
    --column-count: 1;
  }
`

const Item = styled.a`
  position: relative;
  padding: 2rem;
  grid-column: span 2;
  grid-row: span 3;
  display: grid;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  gap: 0 1rem;
  @media (max-width: 40rem){
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &:nth-child(2n + 1)::before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: -0.0625rem;
    background-image: linear-gradient(to bottom, #FF7BAB, #FF921E);
    @media (max-width: 50rem){
      display: none;
    }
  }
  &::after {
    content: '';
    display: block;
    height: 0.125rem;
    width: 200%;
    position: absolute;
    bottom: -0.0625rem;
    left: 0;
    background-image: linear-gradient(to right, #FF7BAB, #FF921E);
    @media (max-width: 50rem){
      width: 100%;
    }
  }
  &:nth-child(2n)::after{
    display: none;
    @media (max-width: 50rem){
      display: block;
    }
  }
  &:last-child::after,
  &:nth-last-child(2):nth-child(odd)::after {
    display: none;
    @media (max-width: 50rem){
      display: block;
    }
  }
`

const ImageContainer = styled.div`
  grid-row: span 3;
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
  }
`

const ItemInner = styled.div`
  grid-row: span 3;
  display: grid;
  grid-template-rows: subgrid;
`

const Title = styled.div`
  font-family: serif;
  font-weight: 700;
  line-height: 1.25;
  font-size: 1.25rem;
  background-image: linear-gradient(to right, #FF7BAB, #FF921E);
  width: fit-content;
  -webkit-background-clip: text;
  transition: color 0.4s;
  ${Item}:hover & {
    color: transparent;
  }
`

const TitleContainer = styled.div`

`

const Description = styled.div`
  line-height: 1.75;
  font-size: 0.875rem;
  margin-top: 1rem;
`

const DescriptionInner = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ReadMore = styled.div`
  font-weight: 700;
  margin-left: auto;
  width: max-content;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
  cursor: pointer;
`

type Publication = {
  isbn: string;
  title: string;
  author: string;
  publisher: string;
  description: string;
  type: string;
  releaseDate: Date;
  cover: {
    url: string,
    width: number,
    height: number
  };
}

type WorksSectionProps = {

}
export const WorksSection: FunctionComponent<WorksSectionProps> = () => {
  const [hide, setHide] = useState(true)
  const publicationsQuery = usePublicationsQuery({
    variables: {
      limit: 10000
    }
  })
  const publications = publicationsQuery.data?.publications?.nodes?.map<Publication>(node => {
    return {
      isbn: node?.publicationFields?.isbn ?? '',
      title: node?.title ?? '',
      author: node?.publicationFields?.author ?? '',
      publisher: node?.publicationFields?.publisher ?? '',
      description: node?.publicationFields?.description ?? '',
      type: node?.publicationFields?.type ?? '',
      releaseDate: dayjs(node?.publicationFields?.releasedate ?? '', 'DD/MM/YYYY').toDate(),
      cover: {
        url: node?.publicationFields?.cover?.mediaItemUrl ?? '',
        width: node?.publicationFields?.cover?.mediaDetails?.width ?? 0,
        height: node?.publicationFields?.cover?.mediaDetails?.height ?? 0
      }
    }
  }) ?? []
  return <Outer id="works">
    <SectionTitle>
      WORKS
    </SectionTitle>
    <Inner>
      {
        publications.toSorted((a, b) => dayjs(b.releaseDate).valueOf() - dayjs(a.releaseDate).valueOf()).slice(0, hide ? 6 : undefined).map(publication => {
          return <Item key={publication.isbn}>
            <ImageContainer>
              <img src={publication.cover.url} width={publication.cover.width} height={publication.cover.height}/>
            </ImageContainer>
            <ItemInner>
              <TitleContainer>
                <Title>{publication.title}</Title>
              </TitleContainer>
              <Description>
                <DescriptionInner>
                  {publication.description.replaceAll('\n', '')}
                </DescriptionInner>
              </Description>
            </ItemInner>
          </Item>
        })
      }
    </Inner>
    <ReadMore onClick={() => setHide(v => !v)}>
      <GradientText>
        {
          hide ? 'READ MORE' : 'HIDE'
        }
      </GradientText>
    </ReadMore>
  </Outer>
} 
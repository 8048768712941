import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../components/section-title'

const Outer = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  scroll-margin-top: 2rem;
`
const Inner = styled.div`
  margin-top: 0.25rem;
  border: 0.125rem solid #f00;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #FF7BAB, #FF921E);
  padding: 2rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Name = styled.div`
  font-family: serif;
  font-size: 1.25rem;
  font-weight: 700;
`

const Description = styled.div`
  margin-top: 1rem;
  line-height: 1.5;
  font-size: 0.875rem;
`

type ProfileSectionProps = {

}
export const ProfileSection: FunctionComponent<ProfileSectionProps> = () => {
  return <Outer id="profile">
    <SectionTitle>
      PROFILE
    </SectionTitle>
    <Inner>
      <Name>はるおか りの</Name>
      <Description>
        熊本県出身。2010年度ロマン大賞受賞。おもな著作に「後宮史華伝」シリーズ（集英社オレンジ文庫、コバルト文庫）、「九天に鹿を殺す」（集英社オレンジ文庫）、「ユーレイギフト」（集英社オレンジ文庫、陽丘莉乃名義）、「耀帝後宮異史」シリーズ（小学館文庫）、「覇王の後宮　天命の花嫁と百年の寵愛」（ポプラ文庫ピュアフル）など。葵木あんねの筆名でも著作がある。
      </Description>
    </Inner>
  </Outer>
}
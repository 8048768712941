import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHeader } from '../sections/top-header'
import { NewsSection } from '../sections/news-section'
import { WorksSection } from '../sections/works-section'
import { ProfileSection } from '../sections/profile-section'
import { ContactSection } from '../sections/contact-section'

const Outer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 80rem;
  padding: 2rem;
  box-sizing: border-box;
  @media (max-width: 40rem){
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const HorizontalContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 2rem;
  @media (max-width: 50rem){
    grid-template-columns: 1fr;
  }
`

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <Outer>
    <TopHeader />
    <NewsSection />
    <WorksSection />
    <HorizontalContainer>
      <ProfileSection />
      <ContactSection />
    </HorizontalContainer>
  </Outer>
} 
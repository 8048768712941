import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { GradientText } from './gradient-text'

const Outer = styled.div`
  padding: 2rem 0;
`

const Copyright = styled.div`
  font-size: 0.75rem;
  width: max-content;
  margin: 0 auto;
  line-height: 1.5;
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <Copyright>
      <GradientText>
        Copyright ©️ 2023 はるおかりの All Rights Reserved.
      </GradientText>
    </Copyright>
  </Outer>
}
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../components/section-title'

const Outer = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  scroll-margin-top: 2rem;
`

const Inner = styled.div`
  margin-top: 0.25rem;
  border: 0.125rem solid #f00;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #FF7BAB, #FF921E);
  padding: 2rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Text1 = styled.div`
`

const Email = styled.a`
  display: block;
  font-size: 1.25rem;
  margin-top: 1.25rem;
  text-decoration: underline;
  line-height: 1.5;
  background-image: linear-gradient(to right, #FF7BAB, #FF921E);
  width: fit-content;
  -webkit-background-clip: text;
  transition: color 0.4s;
  &:hover {
    color: transparent;
  }
`

const Text3 = styled.div`
  margin-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
`

type ContactSectionProps = {

}
export const ContactSection: FunctionComponent<ContactSectionProps> = () => {
  return <Outer id="contact">
    <SectionTitle>
      CONTACT
    </SectionTitle>
    <Inner>
      <Text1>
        お仕事のご相談はこちらまで
      </Text1>
      <Email href="mailto:hanawoidaite@gmail.com">
        hanawoidaite@gmail.com
      </Email>
      <Text3>
        ＊個人の方からのご連絡にはお返事できません。<br />
        ＊作品のご感想は各編集部にお願いします。
      </Text3>
    </Inner>
  </Outer>
}
import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import { GradientText } from '../components/gradient-text'

const Outer = styled.div`
  position: relative;
  padding: 4rem 0;
  @media (max-width: 40rem){
    padding: 0;
  }
`

const LogoContainer = styled.a`
  display: block;
  svg {
    display: block;
    width: 16rem;
    height: auto;
    @media (max-width: 40rem){
      width: 12rem;
    }
  }
`

const NavigatorList = styled.div`
  margin-top: 4rem;
  @media (max-width: 40rem){
    margin-top: 2rem;
  }
`

const NavigatorListItem = styled.a`
  display: block;
  font-weight: 900;
  padding: 0.5rem 0;
  width: max-content;
  &:hover * {
    color: #612c26;
  }
`

const scroll = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: calc(var(--background-size) * -1) calc(var(--background-size) * -1);
  }
`

const ImageContainer = styled.div`
  --background-size: 50rem;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 20rem);
  background-image: url('/images/1492432095.webp');
  animation: ${scroll} 120s linear infinite;
  border-radius: 0.25rem;
  background-size: var(--background-size);
  @media (max-width: 40rem){
    --background-size: 30rem;
    position: static;
    width: 100%;
    height: 8rem;
    margin-top: 2rem;
  }
`

type TopHeaderProps = {

}
export const TopHeader: FunctionComponent<TopHeaderProps> = () => {
  return <Outer id="top-header">
    <LogoContainer href="/">
      <SvgLogo />
    </LogoContainer>
    <NavigatorList>
      <NavigatorListItem href="#news">
        <GradientText>
          NEWS
        </GradientText>
      </NavigatorListItem>
      <NavigatorListItem href="#works">
        <GradientText>
          WORKS
        </GradientText>
      </NavigatorListItem>
      <NavigatorListItem href="#profile">
        <GradientText>
          PROFILE
        </GradientText>
      </NavigatorListItem>
      <NavigatorListItem href="#contact">
        <GradientText>
          CONTACT
        </GradientText>
      </NavigatorListItem>
    </NavigatorList>
    <ImageContainer />
  </Outer>
}
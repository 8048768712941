import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  background-image: linear-gradient(to right, #FF7BAB, #FF921E);
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

type GradientTextProps = {
  children?: ReactNode
}
export const GradientText: FunctionComponent<GradientTextProps> = ({children}) => {
  return <Outer>
    {children}
  </Outer>
}
import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { GradientText } from './gradient-text'

const Outer = styled.div`
  font-size: 2rem;
  font-family: 'EB Garamond', serif;
  font-weight: 600;
`

type SectionTitleProps = {
  children?: ReactNode
}
export const SectionTitle: FunctionComponent<SectionTitleProps> = ({children}) => {
  return <Outer>
    <GradientText>
      {children}
    </GradientText>
  </Outer>
}
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { GradientText } from './gradient-text'
import SvgLogo2 from '../generated/svg/logo2'
import { useBreakpoint } from '../hooks/use-break-point'
import SvgLogo from '../generated/svg/logo'
import classNames from 'classnames'

const Outer = styled.div`
  position: fixed;
  width: calc(100% + 2rem);
  top: -1rem;
  left: -1rem;
  backdrop-filter: saturate(240%) blur(20px);
  background-color: hsla(0,0%,100%,.65);
  z-index: 100;
  padding: 2rem 3rem 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  transition: opacity 0.4s, filter 0.8s;
  &.hide {
    opacity: 0;
    pointer-events: none;
    filter: blur(1rem);
  }
  @media (max-width: 40rem){
    padding: 2rem 3rem 0.5rem 3rem;
    flex-direction: column;
  }
`

const LogoContainer = styled.a`
  display: block;
  svg {
    display: block;
    width: 10rem;
    height: auto;
    margin: 0 auto;
    @media (max-width: 40rem){
      width: 8rem;
    }
  }
`

const NavigatorList = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  @media (max-width: 40rem){
    margin-top: 1rem;
    margin-top: 0.5rem;
    gap: 0;
  }
`

const NavigatorListItem = styled.a`
  display: block;
  padding: 0.5rem 0;
  width: max-content;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 0.875rem;
  font-weight: 500;
  }
`

type FloatHeaderProps = {

}
export const FloatHeader: FunctionComponent<FloatHeaderProps> = () => {
  const isMobile = useBreakpoint('40rem')
  const [hide, setHide] = useState(true)
  useEffect(() => {
    const listener = () => {
      const topHeader = document.getElementById('top-header')
      const floatHeader = document.getElementById('float-header')
      if(topHeader && floatHeader){
        const topHeaderBoundingRect = topHeader.getBoundingClientRect()
        const floatHeaderBoundingRect = floatHeader.getBoundingClientRect()
        if(topHeaderBoundingRect.bottom < floatHeaderBoundingRect.height){
          setHide(false)
        } else {
          setHide(true)
        }
      }
    }
    window.addEventListener('scroll', listener)
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('scroll', listener)
      window.removeEventListener('resize', listener)
    }
  })
  return <Outer id="float-header" className={classNames({hide})}>
    <LogoContainer href="/">
      {
        isMobile ? 
          <SvgLogo2 /> :
          <SvgLogo />
      }
    </LogoContainer>
    <NavigatorList>
      <NavigatorListItem href="#news">
        <GradientText>
          NEWS
        </GradientText>
      </NavigatorListItem>
      <NavigatorListItem href="#works">
        <GradientText>
          WORKS
        </GradientText>
      </NavigatorListItem>
      <NavigatorListItem href="#profile">
        <GradientText>
          PROFILE
        </GradientText>
      </NavigatorListItem>
      <NavigatorListItem href="#contact">
        <GradientText>
          CONTACT
        </GradientText>
      </NavigatorListItem>
    </NavigatorList>
  </Outer>
}